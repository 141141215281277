import React, { Component } from 'react'
import { Link } from 'gatsby'
import Video from 'react-player'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-solutions'
import Demo from 'components/s-demo/main'
import Footer from 'components/footer/main'
import SEO from 'components/seo'

// Sass Global
import 'sass/main.scss'

import {
  BannerSolucao,
  AcessoDados,
  ProgramaBeneficios,
  RotinaSeguranca,
  TituloPrimario,
  TituloSecundario,
  TextParagrafo,
  SectionVideo,
} from '../Style/solucoes'

import { Container, TitleBanner } from '../Style/global'

import IlustraGestaoVenda from 'images/ilustra-gestao-venda.svg'
import IlustraPerfilCliente from 'images/ilustracao-perfil-cliente.svg'
import IlustraUsoContinuo from 'images/ilustracao-uso-continuo.svg'
import IlustraMultiplasTelas from 'images/ilustracao-multiplas-telas.svg'
import IlustraDescontosPersonalizados from 'images/ilustracao-desconto-personalizados.svg'
import FarmaciaPopular from 'images/farmacia-popular.png'
import TransactionCentre from 'images/transaction-centre.png'
import Epharma from 'images/epharma.png'
import Vidalink from 'images/vidalink.png'
import Pharmalink from 'images/pharmalink.png'
import Orizon from 'images/orizon.png'
import Febrafar from 'images/febrafar.png'
import ObjectPro from 'images/object-pro.png'
import IlustracaoSegurancaRotina from 'images/ilustracao-seguranca-rotinas.svg'

class PageGestaoVendas extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Gestão de vendas" />

        <Header />

        <Navigation />

        <BannerSolucao>
          <Container className="flex">
            <div className="texto">
              <TitleBanner>Gestão</TitleBanner>
              <h1>Agilidade e controle no processo de venda</h1>
              <p>Vende mais quem realmente conhece o seu cliente!</p>
              <Link to="/sobre/contato">Solicite uma demonstração</Link>
            </div>
            <img src={IlustraGestaoVenda} alt="Tela sistema Inovafarma gráficos de vendas" className="ilustra" />
          </Container>
        </BannerSolucao>

        <AcessoDados>
          <Container>
            <TituloPrimario>Tenha acesso aos dados sobre seu cliente</TituloPrimario>
            <TextParagrafo>
              Conhecer o cliente é o primeiro passo para decolar as vendas e agilizar o dia a dia do seu estabelecimento.
            </TextParagrafo>
            <div className="all-dados">
              <div>
                <img src={IlustraPerfilCliente} alt="Tela sistema Inovafarma perfil do cliente" />
                <TituloSecundario>Perfil do Cliente</TituloSecundario>
                <TextParagrafo>Realize um atendimento personalizado através das informações de consumo dos seus clientes.</TextParagrafo>
              </div>
              <div>
                <img src={IlustraUsoContinuo} alt="Tela sistema Inovafarma uso contínuo" />
                <TituloSecundario>Uso contínuo</TituloSecundario>
                <TextParagrafo>
                  Programe as próximas compras dos seus clientes, realize ações de fidelização e aumente suas vendas.
                </TextParagrafo>
              </div>
              <div>
                <img src={IlustraMultiplasTelas} alt="Tela sistema Inovafarma múltiplas telas de vendas" />
                <TituloSecundario>Múltiplas telas de vendas</TituloSecundario>
                <TextParagrafo>
                  Tenha flexibilidade em utilizar várias telas de vendas para consultar preços e realizar atendimentos simultâneos
                </TextParagrafo>
              </div>
              <div>
                <img src={IlustraDescontosPersonalizados} alt="Tela sistema Inovafarma descontos personalizados" />
                <TituloSecundario>Descontos personalizados</TituloSecundario>
                <TextParagrafo>Ofereça condições especiais de desconto para aumentar a retenção de clientes em sua farmácia.</TextParagrafo>
              </div>
            </div>
          </Container>
        </AcessoDados>

        <ProgramaBeneficios>
          <Container>
            <TituloPrimario>Programas de benefícios de medicamentos</TituloPrimario>
            <TextParagrafo>
              O INOVAFARMA oferece a integração com os principais PBM’s do mercado e Programas de Fidelidade do Brasil
            </TextParagrafo>

            <ul>
              <li>
                <div className="marca">
                  <img src={FarmaciaPopular} alt="Logo Farmácia Popular" />
                </div>
                <h4>Farmácia Popular</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={TransactionCentre} alt="Logo Transaction Centre" />
                </div>
                <h4>Transaction Centre</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={Epharma} alt="Logo Epharma" />
                </div>
                <h4>E-pharma</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={Vidalink} alt="Logo Vidalink" />
                </div>
                <h4>Vidalink</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={Pharmalink} alt="Logo Pharmalink" />
                </div>
                <h4>Pharmalink</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={Orizon} alt="Logo Orizon" />
                </div>
                <h4>Orizon</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={Febrafar} alt="Logo PEC Febrafar" />
                </div>
                <h4>PEC Febrafar</h4>
              </li>
              <li>
                <div className="marca">
                  <img src={ObjectPro} alt="Logo Object Pro" />
                </div>
                <h4>Object Pro</h4>
              </li>
            </ul>
          </Container>
        </ProgramaBeneficios>

        <RotinaSeguranca>
          <Container>
            <TituloPrimario>Tenha mais segurança nas suas rotinas financeiras</TituloPrimario>
            <div className="flex">
              <ul>
                <li>
                  <h4>Checkout de vendas</h4>
                  <p>Evite rupturas na sua farmácia realizando a conferência dos produtos registrados no balcão.</p>
                </li>
                <li>
                  <h4>Gerenciador de entregas</h4>
                  <p>Acompanhe as vendas despachadas para entrega, controlando taxas e valores de recebimento.</p>
                </li>
                <li>
                  <h4>Controle de caixa</h4>
                  <p>Tenha segurança nas operações financeiras de forma prática e eficiente.</p>
                </li>
                <li>
                  <h4>Crediário</h4>
                  <p>Gerencie as contas a receber da sua farmácia, oferecendo maior agilidade para seus clientes.</p>
                </li>
              </ul>
              <img src={IlustracaoSegurancaRotina} alt="Icone sistema Inovafarma completo" />
            </div>
          </Container>
        </RotinaSeguranca>

        <SectionVideo>
          <Container>
            <TituloPrimario>Veja como é simples fazer a gestão de compra e estoque da sua farmácia</TituloPrimario>
            <Video className="video video-gestao-vendas" url="https://www.youtube.com/watch?v=ilP24IcaOnU" light playing />
          </Container>
        </SectionVideo>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageGestaoVendas
